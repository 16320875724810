import React, { useState } from 'react';
import ZielplattformStartLarge from '../assets/SeaLogisticsStart.svg';
import { requestCredentials, VCType } from '@crefotrust/sdk';
import { List, ListItem, Button, DialogContent, Dialog, DialogTitle, CardMedia, CardActionArea, Card } from '@material-ui/core';

const TargetPlatformStart = (props) => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);

  const [credentials, setCredentials] = useState([]);
  const [crefoError, setCrefoError] = useState("");

  const requiredCredentials = [VCType.NAME, VCType.ADDRESS, VCType.NATIONALITY, VCType.BIRTHDATE, VCType.AUTHORIZEDREPRESENTATIVE];

  console.debug(requiredCredentials.join());

  return (
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          src={ZielplattformStartLarge}
          title="Sea Logistics"
          onClick={async () => {
            let expectedIssuer = "did:web:api-dev.crefotrust.de"
            if (window._env_.ENV === "int") {
              expectedIssuer = "did:web:api-int.crefotrust.de"
            } else if (window._env_.ENV === "prod") {
              expectedIssuer = "did:web:api.crefotrust.de"
            } else if (window._env_.ENV === "demo") {
              expectedIssuer = "did:web:api-demo.crefotrust.de"
            } else if (window._env_.ENV === "new_dev") {
              expectedIssuer = "did:web:api-new-dev.crefotrust.de"
            } else if (window._env_.ENV === "new_int") {
              expectedIssuer = "did:web:api-new-int.crefotrust.de"
            } else if (window._env_.ENV === "new_demo") {
              expectedIssuer = "did:web:api-new-demo.crefotrust.de"
            } else if (window._env_.ENV === "new_prod") {
              expectedIssuer = "did:web:api-new-prod.crefotrust.de"
            }
            console.log(expectedIssuer);
            requestCredentials(requiredCredentials, 'Sea Logistics', window._env_.USER_FRONTEND, expectedIssuer)
              .then((res) => {
                console.log(res);
                setOpen(true);
                setCredentials(res);
              })
              .catch((err) => {
                console.error("Got an error");
                console.error(err.message);
                setOpenError(true);
                setCrefoError(err.message);
              })
          }}
        />
      </CardActionArea>
      <Dialog
        onClose={() => { }}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          Verifizierte Credentials
        </DialogTitle>
        <List>
          {Object.keys(credentials).map((credential) => {
            return (
              <ListItem key={credential}>
                <div>
                  <pre
                    style={{
                      fontFamily: 'Courier',
                      width: '100%',
                      fontSize: '10px',
                      background: '#f4f4f4',
                      border: 'solid 1px #e1e1e1',
                      float: 'left',
                    }}
                  >
                    {credential + " : " + JSON.stringify(credentials[credential])}
                  </pre>
                </div></ListItem>
            );
          })}
        </List>
        <Button onClick={() => {
          window.location = '/end'
        }}>Abschließen</Button>
      </Dialog>
      <Dialog
        onClose={() => { }}
        aria-labelledby="simple-dialog-title"
        open={openError}
      >
        <DialogTitle id="simple-dialog-title">
          Ein Fehler ist aufgetreten
        </DialogTitle>
        <DialogContent>
          {crefoError}
        </DialogContent>
        <Button onClick={() => {
          setOpenError(false);
        }}>Abschließen</Button>
      </Dialog>
    </Card>
  );
};

export default TargetPlatformStart;
