import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import ZielplattformEndLarge from '../assets/SeaLogisticsEnd.svg';

const TargetPlatformEnd = () => {

  return (
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          src={ZielplattformEndLarge}
          title="Sea Logistics"
        />
      </CardActionArea>
    </Card>
  );
}

export default TargetPlatformEnd;