import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './manager/History';
import TargetPlatformStart from './components/TargetPlatformStart';
import TargetPlatformEnd from './components/TargetPlatformEnd';

function App() {
  return (
    <div className='App'>
      <Router history={history}>
        <div className='container marginTop'>
          <Switch>
            <Route exact path='/end' component={TargetPlatformEnd} />
            <Route exact path='/*' component={TargetPlatformStart} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;